import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store.js";

import axios from "axios";
import VueAxios from "vue-axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import withUUID from "vue-uuid";

import i18n from "./services/i18n.service";

import ApiService from "@/services/api.service";

ApiService.setHeader();

// Function to check for specific parameter
function getIdFromUrl(query = "id") {
  // Create a new URLSearchParams object with the query string
  const params = new URLSearchParams(window.location.search);
  if (params.has(query)) {
    return params.get(query);
  } else {
    return null;
  }
}
function loadApp() {
  const app = createApp(App);
  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(VueAxios, axios);
  app.use(VueSweetalert2, { confirmButtonColor: "#e03339" });
  app.use(withUUID);
  app.use(ToastPlugin);

  app.mount("#app");
}

(async () => {
  if (!localStorage.getItem("countryCode"))
    localStorage.setItem("countryCode", "964");
  const id = getIdFromUrl("id");
  const code = getIdFromUrl("code");
  if (id) {
    await ApiService.refreshToken(id)
    .finally(() => {
      if (code) {
        localStorage.setItem("countryCode", code);
      }
      loadApp();
    });
  } else {
    await axios
      .get("https://gamefluence.net:1337/api/v1" + "/auth/ip/info")
      .then((response) => {
        localStorage.setItem("countryCode", response.data.data);
      })
      .finally(() => {
        if (code) {
          localStorage.setItem("countryCode", code);
        }
        // cons('dd')
        loadApp();
      });
  }
})();
